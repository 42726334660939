"use strict";

// import von node_modules
import jQuery from "jquery";
import $ from "jquery";


const defaults = {
    selectors: {
        navbar: '.js-navbar',
        topbar: '.js-top-bar',
        section: '.js-section',
        menuLink: '.js-menu-link',
        navigation: '.js-navigation',
        mobileMenuBtn: '.js-mobile-menu-btn',
        subMenuToggle: '.js-sub-menu-toggle',
        subMenu: '.js-sub-menu'
    },
    // settings: {
    //     scrollDuration: 400,
    // }66
};

const state = {
    navInit: false,
};


export default function () {
    $(defaults.selectors.topbar);
    $(defaults.selectors.section);
    $(defaults.selectors.menuLink);
    $(defaults.selectors.navigation);
    $(defaults.selectors.mobileMenuBtn);
    $(defaults.selectors.topbar);
    $(defaults.selectors.subMenuToggle);
    $(defaults.selectors.subMenu);
    state.navInit = true;
    stickyNavbar();
    toggleMobileMenu();
    openMobileSubMenu();
}


export function stickyNavbar() {
    let navbar = document.querySelector(defaults.selectors.navbar);

    if (!navbar) return false;

    var sticky = navbar.offsetTop;

    window.onscroll = function() {
        if (window.pageYOffset > sticky) {
            navbar.classList.add("is-scrolled");
        } else if (window.pageYOffset <= sticky) {
            navbar.classList.remove("is-scrolled");
        }
    };
};

function toggleMobileMenu() {

    let mobileMenuBtn = document.querySelector(defaults.selectors.mobileMenuBtn);
    let nav = document.querySelector(defaults.selectors.navigation);
    let navbar = document.querySelector(defaults.selectors.navbar);

    if (!mobileMenuBtn) return false;
    if (!nav) return false;
    if (!navbar) return false;

    mobileMenuBtn.addEventListener('click', function (event) {
        event.preventDefault();
        if ($(nav).hasClass("is-open-mobile-menu")) {
            $(mobileMenuBtn).find("use").attr("xlink:href", "#icon-menu");
            $(nav).slideUp("200", function () {
                $(nav).removeClass("is-open-mobile-menu");
                $(navbar).removeClass("mobile-menu-overlay");
            });

        } else {
            $(navbar).addClass("mobile-menu-overlay");
            $(nav).addClass("is-open-mobile-menu");
            $(nav).slideDown("200");
            $(mobileMenuBtn).find("use").attr("xlink:href", "#icon-close");
        }
    })
}


function openMobileSubMenu() {

    let allSubMenuToggles = document.querySelectorAll(defaults.selectors.subMenuToggle);
    let allSubMenus = document.querySelectorAll(defaults.selectors.subMenu);

    if (!allSubMenuToggles) return false;
    if (!allSubMenus) return false;

    Array.from(allSubMenuToggles).forEach(subMenuToggle => {

        subMenuToggle.addEventListener('click', function (event) {
            event.preventDefault();

            let openSubmenu = Array.from(allSubMenus).find(item => {
                $(item).parent().hasClass(item, 'is-open')
            })
            if ($(subMenuToggle).parent().hasClass("is-open")) {

                $(subMenuToggle).parent().removeClass("is-open");
                $(subMenuToggle).siblings('.sub-menu').slideUp("200");

            } else {
                $(allSubMenus).parent().removeClass("is-open");
                $(allSubMenus).slideUp("200");
                $(subMenuToggle).parent().addClass("is-open");
                $(subMenuToggle).siblings('.sub-menu').slideDown("200");
            }
        });
    })
}
