import {lang} from './components/helpers.js'

import {vbcnOptions} from "./vbcn-options";
import init from "./vbcn";

import 'es6-promise/auto';
import initMaps from './map-components/mapbox-maps';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();


import bindArrowToTop from './components/arrow-to-top';
import bindScrollLinks from './components/scroll-links';
import lightBox from './components/lightbox';
import bindNavigation from './components/navigation';
import {initOverlay} from "./components/notifications";


function initGlobal() {

	console.log('Language from global: ' + lang);

	//GLOBAL
	bindNavigation();
	bindArrowToTop();
	bindScrollLinks();
	lightBox();
	initMaps();
	initOverlay();

	window.mapboxgl = mapboxgl;
	window.myVBCN = init(vbcnOptions);
}

initGlobal();
