"use strict";

import {nodeListToArray} from "./helpers";
import forEach from 'lodash/forEach';

const defaults = {
  selectors: {
    notificationContainer: '.notification-container',
    notification: '.notification',
    message: '.message',
  },
  fadeOutTime : 2000,
};

const state = {
  notifications: [],
  isNotificationsInitialized: false,
  // isProductNavOpen: false,
  // productNavElem: null,
  // productNavTriggerElems: null,
};

export class Notification {
  constructor(domEl) {
    this.el = domEl;
    // this.parent = document.querySelector

    console.log(this.el);
    this.shouldFadeOut = typeof (this.el.dataset.fadeout) !== 'undefined' ;

    if (this.shouldFadeOut) {
      this.fadeOutTime = !isNaN(parseFloat(this.el.dataset.fadeout)) ? parseFloat(this.el.dataset.fadeout) * 1000 : defaults.fadeOutTime;
    }

    this.closeButton = this.el.querySelector('.delete');

    this.timeOut = null;

    this.bindActions();

  }

  bindActions() {
    let that = this;
    if (this.closeButton) {
        this.closeButton.addEventListener('click', that.removeNotification.bind(this));
    }

    if(this.shouldFadeOut) {
      this.timeOut = setTimeout(that.removeNotification.bind(this), that.fadeOutTime);
    }
  }

  removeNotification() {

    if (this.el) {
      if (this.closeButton) {
          this.closeButton.removeEventListener('click', this.remove);
      }
      clearTimeout(this.timeOut);
      this.el.parentNode.removeChild(this.el);

      // state.filter(this.el)
    }
  }

  createNotification() {

  }
}

export default function initNotifications () {
  const notificationContainerEl = document.querySelector(defaults.selectors.notificationContainer);

  if (!notificationContainerEl) {
    return false;
  }

  const notificationEls = nodeListToArray(notificationContainerEl.querySelectorAll(defaults.selectors.notification));
  const messageEls = nodeListToArray(notificationContainerEl.querySelectorAll(defaults.selectors.message));

  forEach(notificationEls, function (element) {
    let notification = new Notification(element);
    state.notifications.push(notification);
  });

  forEach(messageEls, function (element) {
    let notification = new Notification(element);
    state.notifications.push(notification);
  });

  state.isNotificationsInitialized = true;

  console.log(state);
}

export function initOverlay() {
	var modal = document.querySelector(".js-overlay");
	var btn = document.querySelector(".js-overlay-button");

	if (btn) {
		btn.onclick = function() {
			modal.style.display = "none";
		}
	}
}
