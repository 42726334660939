export const vbcnOptions = {
	notice: {
		ignoredPages: [
			'/datenschutz',
		],
		analytics: {
			addInfo: true
		},
		showOverlayOnInit: false,
		backdropColor: '#fff',
		backdropOpacity: '0.65',
		centerMode: false,
	},

	texts: {
		"de": {
			initialHeadline: 'Cookies, externe Dienste & Datenschutz',
			customizeHeadline: 'Datenschutz-Einstellungen',
			explanationText: "Nach eurer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. \n" +
				"Zur Analyse der Zugriffe auf unsere Website verwenden wir Google Analytics. \n" +
				"Außerdem binden wir Skripte von YouTube und Jotforms ein. \n" +
				"Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klickt <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
			accept_preset_ButtonText: 'Alle akzeptieren',
			declineButtonText: 'Ablehnen ',
			accept_all_ButtonText: 'Alle Cookies akzeptieren',
			decline_all_ButtonText: 'Alle ablehnen',
			customizeButtonText: 'Einstellungen',
			cancel_customizeButtonText: 'Zurück',
			saveButtonText: 'Speichern',
			closeButtonText: '×'
		},
		"en": {
			initialHeadline: 'Accept cookies & Privacy Policy?',
			customizeHeadline: 'Select cookies to accept',
			explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
				"We use Google Analytics to analyze the usage  of our website. \n\n" +
				"We embed scripts from these external service providers: YouTube, Jotforms. \n\n" +
				"On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
			accept_preset_ButtonText: 'Accept all',
			declineButtonText: 'Decline',
			accept_all_ButtonText: 'Accept all',
			decline_all_ButtonText: 'Decline all',
			customizeButtonText: 'Customize',
			cancel_customizeButtonText: 'Go back',
			saveButtonText: 'Save',
			closeButtonText: '×'
		}
	},

	sets: {
		"de": [
			{
				id: 'mandatory',
				name: 'Notwendige Cookies',
				description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
				mandatory: true,
				mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
			},
			{
				id: 'mapbox_maps',
				name: 'Mapbox',
				description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an Mapbox übertragen werden.'
			},
		],
		"en": [
			{
				id: 'mandatory',
				name: 'Essential cookies',
				description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
				mandatory: true,
				mandatory_text: "These cookies are mandatory."
			},
			{
				id: 'mapbox_maps',
				name: 'Mapbox',
				description: 'Scripts and cookies that are required to display the maps of this service. On activation personal data might be forwarded to Mapbox.'
			},
			]
	}
};
