"use strict";

import $ from 'jquery';

const jQuery = require('jquery');
import magnificPopup from 'magnific-popup';


export default function lightBox() {
    // Translate strings
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'X (Esc)',
        tLoading: 'Lade #%curr%...',
        gallery: {
            tPrev: '&#8592;',
            tNext: '&#8594;',
            tCounter: '%curr% / %total%'
        },
        image: {
            tError: 'x-x'
        },
        ajax: {
            tError: 'x-x'
        }
    });
    if ($('.js-gallery').length) {
        $('.js-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">Das Bild #%curr%</a> konnte nicht geladen werden.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            }
        });
    }
}
